/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes documents véto';
export const txtnombredoc = 'document';
export const txtnombredocpluriel = 'documents';
export const titleAlveole = 'Mes documents véto';

export const alveoleLogoSvg = "./../images/icon-alv-docs-veto.svg";
/************************ DeV ******************************************************/
export const zAPIdocuments = process.env.REACT_APP_API_DOCS_VETO_DOCUMENT ;
export const zUrlBack = process.env.REACT_APP_API_DOCS_VETO;
export const zUrlBackNotifications = process.env.REACT_APP_API_SERVICENOTIFICATION;
export const urlZeenDoc = process.env.REACT_APP_API_ZEENDOC;

/************************ LOCAL DeV ************************************************/
// export const zUrlBack = "https://localhost:8000"
// export const zAPIdocuments = "https://localhost:8000/customer-documents";
// export const zUrlBackNotifications = "https://localhost:8001";
export const Typedoc = [
    {id: 3, title: 'BSE - Protocoles de soins'},
    {id: 1, title: 'Ordonnances'},
    {id: 2, title: 'Rapports de visites'},
    {id: 4, title: 'Autres documents'},
];
export const Typedocid = [1, 2, 3, 4];
/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLhome = process.env.REACT_APP_HOME_CUSTOMER;
export const zURLprofile = process.env.REACT_APP_PROFILE;
